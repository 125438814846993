import axios, { AxiosError } from 'axios';
import { BuilderProActions, TAiAction } from '@/store/builder-pro/types';

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL;

const aiActions: Partial<BuilderProActions> = {

  async fetchAiActions() {
    return new Promise<TAiAction[] | AxiosError>((resolve, reject) => {
      axios
        .get(`${BASE_URL}/api/constructor/ai/actions`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        .then(async (res) => {
          const aiActions = res.data?.data?.items || [];
          this.aiActions = aiActions;
          resolve(aiActions as TAiAction[]);
        })
        .catch((e: AxiosError) => {
          this.dataSources = [];
          reject(e);
        });
    });
  },

  createAiText({ actionId, prompt, values }) {
     return axios
      .post(`${BASE_URL}/api/constructor/ai/make-action`, {
        actionId,
        prompt,
        values
      },{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      // .then((res) => {
      // })
      // .catch((e) => {
      //   console.error(e);
      // });
  },
};

export { aiActions };
